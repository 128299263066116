import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import routes from "./routes/route";
import config from "./config/config";
import './App.css';
import UserService from './service/UserService';

function App() {
  var title = "testst"
  return (
    <Router>
      <Switch>
        {routes.publicRoute.map(function (item, key) {
          return (<PublicRoute path={item.path} key={key + item.path}> <item.layout path={item.path} routeData={item} /></PublicRoute>);
        })}
        {routes.privateRoute.map(function (item, key) {
          return (<PrivateRoute exact path={item.path} key={key + item.path}> <item.layout path={item.path} routeData={item} /> </PrivateRoute>);
        })}
        {/* <Route component={NotFoundPage}/> */}
        <Redirect exact to={{ pathname: "/login" }} />
      </Switch>
    </Router>
  );
}

function PublicRoute({ children, ...rest }) {
  let isHaveAgentPram = !(new URLSearchParams(window.location.search).get("a") == "" || new URLSearchParams(window.location.search).get("a") == null)
  let isLogin = localStorage.getItem('login') == 'true';
  if (isLogin && isHaveAgentPram) return window.location.replace(config.CONTACT_US_LINK);
  return (
    <Route
      {...rest}
      render={({ location }) => (isLogin ? <Redirect to={{ pathname: "/home", state: { from: location } }} /> : children)
      }
    />
  );
}

function PrivateRoute({ children, ...rest }) {
  let isLogin = localStorage.getItem('login') == 'true';
  if(isLogin && !(window.location.pathname === '/profile/addbank') && !UserService.getBankAccount()) window.location.replace('/profile/addbank')
  return (
    <Route
      {...rest}
      render={({ location }) => (isLogin ? children : <Redirect to={{ pathname: "/login", state: { from: location } }} />)
      }
    />
  );
}
export default App;
