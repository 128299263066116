import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Avatar, Input, Modal, Layout, Skeleton, Space, message, notification } from 'antd';
import { EnvironmentFilled, SettingOutlined } from "@ant-design/icons";
import io from 'socket.io-client';
import GameMsgBoardComponent, { GameMsgItem } from "../../components/Contents/GameMsgBoardComponent";
import config from "../../config/config";
import Authen from "../../service/AuthenService";
import UserService from "../../service/UserService";
import ConfigValueService from "../../service/ConfigValueService";
import ScrollToBottom from 'react-scroll-to-bottom';

export default class MuayPlayView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gameData: { playRate: [] },
            msg: [],
            loading: true,
            shootPrice: 0,
            minShootPrice: 10,
            calPlayPrice: {
                red: { lossPrice: 0, winPrice: 0, type: "", textRate: "" },
                blue: { lossPrice: 0, winPrice: 0, type: "", textRate: "" }
            },
            credit: 0,
            isActivePlay: false,
            playLoading: false,
            openModal: false
        }
    }

    async componentDidMount() {
        await UserService.fetchMyUser();
        if (Number(UserService.getWallet().credit) < 10) {
            window.location.href = "/profile?msg=กรุณาเติมเงินก่อนเล่น";
            return;
        }

        await ConfigValueService.MIN_PRICE_PLAYMUAY().then(res => {
            if (res.status) this.setState({ minShootPrice: res.data.value })
        })
        //socket init
        this.socket = await io.connect(config.API_CONFIG.SOCKET_URL + "/muayplay", { transports: ["websocket"], query: { token: Authen.getAcessToken(), muayRoudId: this.props.match.params.muayRoudId } })
        // this.socket.emit('MUAY_PLAYLIST')
        this.socket.on('GAME_DATA', data => {
            if (data.status) {
                console.log(data.data)
                this.setState({ gameData: data.data, msg: data.data.msg, loading: false }, () => this.calPlayPrice())
            } else {
                console.log(data.msg)
            }
        })
        this.socket.on('MSG', data => {
            if (data.status) {
                console.log(data.data)
                this.setState({ msg: data.data, })
            } else {
                console.log(data.msg)
            }
        })
        this.socket.on('PLAY', data => {
            if (data.status) {
                console.log(data.data)
                console.log("play success")
                message.success("สำเร็จ");
                // reload เงิน //
                UserService.fetchMyUser().then(res => this.setState({ playLoading: false }))
            } else {
                console.log(data.msg)
                // message.error(data.msg);
                notification.error({ message: data.msg });
                this.setState({ playLoading: false })
            }
        })
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    async play(shootType) {
        const playRateActive = this.getPlayRateActive()
        if (!playRateActive) return message.warning('ปิดรอบการแทงแล้ว');
        await UserService.fetchMyUser()
        let credit = Number(UserService.getWallet().credit)
        this.setState({ credit: credit })
        if (credit < this.state.shootPrice) return message.warning('ยอดเงินไม่เพียงพอ');
        this.socket.emit("PLAY", {
            shootType: shootType,
            price: this.state.shootPrice,
            playRateId: playRateActive._id
        }
        )
        this.state.calPlayPrice.blue.lossPrice = 0
        this.state.calPlayPrice.blue.winPrice = 0
        this.state.calPlayPrice.red.lossPrice = 0
        this.state.calPlayPrice.red.winPrice = 0
        this.setState({
            shootPrice: 0,
            playLoading: true
        })
    }

    handalInputShootPrice = (event) => {
        this.setState({ shootPrice: parseInt(Number(event.target.value)), credit: Number(UserService.getWallet().credit) }, () => this.calPlayPrice())
    }

    calPlayPrice() {
        let calPlayPrice = {
            red: { lossPrice: 0, winPrice: 0, type: "", textRate: "" },
            blue: { lossPrice: 0, winPrice: 0, type: "", textRate: "" },
        }
        const price = this.state.shootPrice
        const playRateActive = this.getPlayRateActive()
        if (playRateActive) {
            this.setState({ isActivePlay: true })
            if (playRateActive.red.redWeight > playRateActive.red.blueWeight) {//ถ้าแดงต่อ
                calPlayPrice.red.type = "ต่อ"
                calPlayPrice.red.textRate = `ดต ${playRateActive.red.redWeight}/${playRateActive.red.blueWeight}`
                calPlayPrice.red.lossPrice = Math.ceil(price * playRateActive.red.redWeight / playRateActive.red.blueWeight)
                calPlayPrice.red.winPrice = price
            } else if (playRateActive.red.redWeight < playRateActive.red.blueWeight) {//ถ้าแดงรอง
                calPlayPrice.red.type = "รอง"
                calPlayPrice.red.textRate = `ดร ${playRateActive.red.blueWeight}/${playRateActive.red.redWeight}`
                calPlayPrice.red.lossPrice = price
                calPlayPrice.red.winPrice = Math.ceil(price * playRateActive.red.blueWeight / playRateActive.red.redWeight)
            } else {
                calPlayPrice.red.type = "เสมอ"
                calPlayPrice.red.textRate = `ดร ${playRateActive.red.blueWeight}/${playRateActive.red.redWeight} (เสมอ)`
                calPlayPrice.red.lossPrice = price
                calPlayPrice.red.winPrice = price
            }

            if (playRateActive.blue.blueWeight > playRateActive.blue.redWeight) {//ถ้าน้ำเงินต่อ
                calPlayPrice.blue.type = "ต่อ"
                calPlayPrice.blue.textRate = `งต ${playRateActive.blue.blueWeight}/${playRateActive.blue.redWeight}`
                calPlayPrice.blue.lossPrice = Math.ceil(price * playRateActive.blue.blueWeight / playRateActive.blue.redWeight)
                calPlayPrice.blue.winPrice = price
            } else if (playRateActive.blue.blueWeight < playRateActive.blue.redWeight) {//ถ้าน้ำเงินรอง
                calPlayPrice.blue.type = "รอง"
                calPlayPrice.blue.textRate = `งร ${playRateActive.blue.redWeight}/${playRateActive.blue.blueWeight}`
                calPlayPrice.blue.lossPrice = price
                calPlayPrice.blue.winPrice = Math.ceil(price * playRateActive.blue.redWeight / playRateActive.blue.blueWeight)
            } else {
                calPlayPrice.blue.type = "เสมอ"
                calPlayPrice.blue.textRate = `งร ${playRateActive.blue.redWeight}/${playRateActive.blue.blueWeight} (เสมอ)`
                calPlayPrice.blue.lossPrice = price
                calPlayPrice.blue.winPrice = price
            }
            this.setState({ calPlayPrice: calPlayPrice })
        } else {
            this.setState({ isActivePlay: false })
        }
    }

    getPlayRateActive = () => this.state.gameData.playRate.slice(0).reverse().find(v => v.isActive)

    showConfirmPlay(shootType) {
        const _this = this
        let text = shootType == "RED" ? <div>{this.state.calPlayPrice.red.textRate}<br />จำนวน {this.state.shootPrice}บ.<br />ได้ {this.state.calPlayPrice.red.winPrice} เสีย {this.state.calPlayPrice.red.lossPrice}</div> : <div>{this.state.calPlayPrice.blue.textRate}<br />จำนวน {this.state.shootPrice}บ.<br />ได้ {this.state.calPlayPrice.blue.winPrice} เสีย {this.state.calPlayPrice.blue.lossPrice}</div>
        Modal.confirm({
            title: 'ยืนยันรายการเดิมพัน',
            //   icon: <ExclamationCircleOutlined />,
            content: text,
            onOk() { _this.play(shootType) },
            onCancel() { console.log('Cancel play') }
        });
    }

    getMessageList = () => this.state.gameData.isShowOrderOtherUser ? this.state.msg : this.state.msg.filter(v => v.userId == UserService.getMyUser()._id || v.userId == 'system' || v.userId == 'admin')

    render() {
        return (
            <Skeleton active loading={this.state.loading} round>
                <div className='MuayPlayView'>
                    <div className='MuayPlayView-header'
                        style={
                            (!!this.state.gameData.liveURL && this.state.gameData.liveURL != '-') ?
                                {} : {
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexDirection: 'column'
                                }}>
                        <div style={{ position: 'absolute', right: 0, top: 45 }}>
                            <Button
                                size="small"
                                danger
                                onClick={() =>
                                    this.setState({ openModal: true })
                                }>กฎกติกา</Button>
                        </div>
                        <Modal
                            onOk={() => this.setState({ openModal: false })}
                            onCancel={() => this.setState({ openModal: false })}
                            visible={this.state.openModal}
                            title="กฎกติกา"
                            footer={[
                                <Button key="submit" type="primary" onClick={() => this.setState({ openModal: false })}>
                                    ปิด
                                </Button>,
                            ]}
                        >
                            <div>
                                <p>
                                    1) กรณีเปิดผิดมุมยกเลิกการเล่นไม่ได้เสีย เช่น แดงต่อ 3-1 เปิดเล่น เงินต่อ 3-1
                                </p>
                                <p>
                                    2) ห้ามเล่นเกินเงินที่ฝากหรือเครดิตที่มีอยู่
                                </p>
                                <p>
                                    3) เล่นแล้วไม่สามารถยกเลิกได้
                                </p>
                                <p>
                                    4) กรณีมวย เสมอ ไล่ลง ยกเลิก ไม่ได้เสีย
                                </p>
                                <p>
                                    5) ได้เสีย ตามภาพตามหลักฐานข้อความที่กดเล่น
                                </p>
                                <p>
                                    6) การตัดสินให้ถือเอาผลการรวมคะแนนและชูมือของกรรมการเป็นหลัก  หากมีการยกเลิกภายหลังที่กรรมการชูมือไปแล้ว จะไม่เป็นผลย้อนหลัง (กรรมการชูมือฝ่ายไหนก็ให้ถือเป็นผลแพ้ชนะ การพลิกคำตัดสินหลังจากนั้นถือว่าไม่มีผลย้อนหลัง)
                                </p>
                            </div>
                        </Modal>
                        <center>
                            {
                                (!!this.state.gameData.liveURL && this.state.gameData.liveURL != '-') &&
                                <iframe width="100%" height="25%" src={this.state.gameData.liveURL} title="Live" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{ marginBottom: -8 }}></iframe>

                            }
                            {this.state.isActivePlay && (
                                <Row style={{ color: '#FFF' }}>
                                    <Col span={12} bg-color='red' style={{ padding: "3px 0px" }}>{this.state.calPlayPrice.red.textRate}</Col>
                                    <Col span={12} bg-color='blue' style={{ padding: "3px 0px" }}>{this.state.calPlayPrice.blue.textRate}</Col>
                                </Row>
                            )}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {console.log(this.state.gameData?.redName)}
                                <div className="MuayPlayView-name-red">🔴{this.state.gameData?.redMan?.firstName + ' ' + this.state.gameData?.redMan?.lastName}</div>
                                <div className="MuayPlayView-name-blue">🔵{this.state.gameData?.blueMan?.firstName + ' ' + this.state.gameData?.blueMan?.lastName}</div>
                            </div>
                            <div bg-color={this.state.isActivePlay ? 'success' : 'danger'} style={{ textAlign: 'center' }}>
                                {this.state.isActivePlay ? 'เปิดรับแทง' : this.state.gameData.status == 2 ? 'ปิดรับแทง' : this.state.gameData.winner ? 'ตัดรอบแล้ว' : 'ปิดรอบ'}
                            </div>
                        </center>
                    </div>
                    <ScrollToBottom debug={false} className='MuayPlayView-msg'>
                        <GameMsgBoardComponent>
                            {this.getMessageList().map((m, key) => <GameMsgItem key={key} isMe={m.userId == (UserService.getMyUser()._id)} text={m.text} />)}
                        </GameMsgBoardComponent>
                    </ScrollToBottom>
                    <Layout.Footer className="tabbar static-bottom">
                        <Space className='plat-rate-List' size={7} align='baseline' style={{ padding: "3px", width: '100%', marginBottom: 3 }} >
                            {this.state.gameData.playRate.map((item, key) =>
                                <Button key={key} type={item.isActive ? "primary" : "dashed"} color={item.isActive ? "green" : ""} size='small' shape="round">{item.prefix}</Button>
                            )}
                        </Space>
                        <div style={{ padding: '3px 5px' }}>
                            <Row gutter={[5, 2]}>
                                <Col span={24}>
                                    <Input type='number' placeholder="ไส่ราคาเดิมพัน" suffix="฿" value={this.state.shootPrice > 0 ? this.state.shootPrice : ""} onChange={this.handalInputShootPrice} />
                                </Col>
                                <Col span={24}><span style={{ fontSize: 12 }}>* แทงขั้นตํ่า {this.state.minShootPrice} {this.getPlayRateActive()?.red.userPriceLimit && `/ แดงรวมสูงสุด ${this.getPlayRateActive().red.userPriceLimit}`} {this.getPlayRateActive()?.blue.userPriceLimit && `/ น้ำเงินรวมสูงสุด ${this.getPlayRateActive().blue.userPriceLimit}`}</span></Col>
                                <Col span={12} className='content-center'>
                                    <Button loading={this.state.playLoading} className='btn-shoot' type="primary" color='red' size='large' shape='round' block style={{ height: 'auto' }} onClick={() => this.showConfirmPlay('RED')} disabled={this.state.shootPrice <= 0 || !this.state.isActivePlay || this.state.shootPrice < this.state.minShootPrice || this.state.calPlayPrice.red.lossPrice > this.state.credit}>
                                        {!this.state.playLoading && <h4>แดง<br /><span style={{ fontSize: 10 }}>{this.state.calPlayPrice.red.type} ได้ {this.state.calPlayPrice.red.winPrice} เสีย {this.state.calPlayPrice.red.lossPrice}</span></h4>}
                                    </Button>
                                </Col>
                                <Col span={12} className='content-center'>
                                    <Button loading={this.state.playLoading} className='btn-shoot' type="primary" color='blue' size='large' danger shape='round' block style={{ height: 'auto' }} onClick={() => this.showConfirmPlay('BLUE')} disabled={this.state.shootPrice <= 0 || !this.state.isActivePlay || this.state.shootPrice < this.state.minShootPrice || this.state.calPlayPrice.blue.lossPrice > this.state.credit}>
                                        {!this.state.playLoading && <h4>น้ำเงิน<br /><span style={{ fontSize: 10 }}>{this.state.calPlayPrice.blue.type} ได้ {this.state.calPlayPrice.blue.winPrice} เสีย {this.state.calPlayPrice.blue.lossPrice}</span></h4>}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Layout.Footer>
                </div >
            </Skeleton >
        )
    };

};