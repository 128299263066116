import React, { useEffect, useState } from "react";
import { Tabs, Skeleton, Row, Col, Button } from 'antd';
import Config from '../../config/config';
import AuthenService from '../../service/AuthenService';
import MyOrderMuayPakYokComponent from '../../components/Contents/MyOrderMuayPakYokComponent'
import MyOrderMuayStepComponent from '../../components/Contents/MyOrderMuayStepComponent'

const MuayHistoryView = () => {
  const [loading, setLoading] = useState(true)
  const [muayhistory, setMuayHistory] = useState([])
  const [limit, setLimit] = useState(5)

  const { TabPane } = Tabs;

  useEffect(() => {
    
  }, [limit])

  function callback(key) {
    // console.log(key);
  }

  return (
      <div className='muay-history'>
        <Tabs defaultActiveKey="2" onChange={callback} centered>
          {/* <TabPane tab="มวยเดี่ยว" key="1" disabled>
            Content มวยเดี่ยว
          </TabPane> */}
          <TabPane tab="มวยพักยก" key="2">
            <MyOrderMuayPakYokComponent/>
          </TabPane>
          <TabPane tab="มวยชุด" key="3">
            <MyOrderMuayStepComponent />
      </TabPane>
        </Tabs>
      </div>
  )
}
export default MuayHistoryView