import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Row, Col, PageHeader, Button, Badge } from "antd";
import {
  HomeFilled,
  BellFilled,
  AppstoreFilled,
  MessageFilled,
  UserOutlined,
  ContainerFilled,
} from "@ant-design/icons";
// import '../../assets/css/MainTabBarComponent.less';
import muayPakYokIcon from '../../assets/img/muay_pak_yok.png'
import muayStepIcon from '../../assets/img/muay_step.png'
import BillIcon from '../../assets/img/bill.png'
import MeIcon from '../../assets/img/me.png'

const MainTabBarComponent = (props) => {
  const [haveMessage, sethaveMessage] = useState(true);
  const [haveNotify, sethaveNotify] = useState(true);

  return (
    <div
      className="tabbar static-bottom content-center"
      style={{ alignItems: "center", paddingBottom: 20 }}
    >
      <Row style={{ padding: "5px 5vw", fontSize: "1.5rem", maxWidth: 1000, width: '100%' }}>
        <Col span={6} style={{ textAlign: "center" }}>
          <Link to="/home">
            <BtnTabbar icon={<img src={muayPakYokIcon} width={30} height={30} />} isActive={false} />
            <div style={{ fontSize: 11 }}>มวยพักยก</div>
          </Link>
        </Col>
        <Col span={6} style={{ textAlign: "center" }}>
          <Link to="/MuayOther">
            <BtnTabbar icon={<img src={muayStepIcon} width={30} height={30} />} isActive={false} />
            <div style={{ fontSize: 11 }}>มวยชุด</div>
          </Link>
        </Col>
        <Col span={6} style={{ textAlign: "center" }}>
          <Link to="/Muayhistory">
            <BtnTabbar icon={<img src={BillIcon} width={30} height={30} />} isActive={false} />
            <div style={{ fontSize: 11 }}>บิล</div>
          </Link>
        </Col>
        <Col span={6} style={{ textAlign: "center" }}>
          <Link to="/profile">
            <BtnTabbar icon={<img src={MeIcon} width={30} height={30} />} isActive={true} />
            <div style={{ fontSize: 11 }}>ตัวฉัน</div>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

const BtnTabbar = ({ icon, isActive = false }) => {
  function getClassName() {
    return !isActive ? "btn-tabbar" : "btn-tabbar btn-tabbar-active";
  }

  return <span className={getClassName()}>{icon}</span>;
};

const BtnTabbarCustom = ({ icon, isActive = false }) => {
  function getClassName() {
    return !isActive
      ? "btn-tabbar-custom"
      : "btn-tabbar-custom btn-tabbar-custom-active";
  }

  return <span className={getClassName()}>{icon}</span>;
};
export default MainTabBarComponent;
