import React from "react";
import { Avatar, Row, Col,PageHeader, Button, Typography } from "antd";
import { UserOutlined,ArrowLeftOutlined } from "@ant-design/icons";
import UserService from "../../service/UserService";
import Logo_img from "../../assets/img/logo.png";

class WalletNavber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wallet: UserService.getWallet(),
        }
    }

    async componentDidMount() {
        await UserService.fetchMyUser()
        this.setState({ wallet: UserService.getWallet() });
        this.syncWallet()
    }

    syncWallet = () => setInterval(() => {
        this.setState({ wallet: UserService.getWallet()});
    }, 500);
    
    render() {
        return (
        <>
            <div>
            <Row style={{padding:"0vw 5vw"}}>
                <Col span={12}>
                    {(this.props.enableBack == true ) && <ArrowLeftOutlined onClick={(event) => window.history.back()} style={{marginRight:5}} />}
                    <Avatar size='default' src={localStorage.getItem('userImg')} style={{marginRight:5}}/>
                    {UserService.getMyUser().name}
                </Col>
                <Col span={12} style={{ textAlign: "right"}}>
                    <span>เครดิต {parseInt(this.state.wallet.credit)}</span>
                </Col>
            </Row>
            </div>
        </>
        );
    }
}
export default WalletNavber;
