import MainNavbar from "../components/Navbars/MainNavbar";
import AuthLayout from "../layouts/AuthLayout.js";
import MainLayout from "../layouts/MainLayout.js";
import PlayGameLayout from "../layouts/PlayGameLayout";
import LoginView from "../views/auth/LoginView.js";
import HomeView from "../views/home/HomeView";
import WalletView from "../views/profile/WalletView";
import AddฺBankView from "../views/profile/AddฺBankView";
import MuayPlayView from "../views/home/MuayPlayView";
import MuayMyOrderView from "../views/myorder/MuayMyOrderView";
import MuayOtherView from '../views/muayOther/MuayOtherView';

var routes = {
  publicRoute:[
    {
      path: "/login",
      name: "เข้าสู่ระบบ",
      icon: "ni ni-key-25 text-info",
      layout: AuthLayout,
      component: LoginView
    },
  ],

  privateRoute:[
    {
      path: "/home",
      name: "หน้าหลัก",
      icon: "ni ni-key-25 text-info",
      layout: MainLayout,
      component: HomeView,
    },
    {
      path: "/home/muayplay/:muayRoudId",
      name: "มวย",
      icon: "ni ni-key-25 text-info",
      backButton:true,
      enableFooter:false,
      layout: PlayGameLayout,
      component: MuayPlayView,
    },
    {
      path: "/profile",
      name: "กระเป๋าเงิน",
      icon: "ni ni-key-25 text-info",
      backButton:false,
      layout: MainLayout,
      component: WalletView,
      navBar: MainNavbar
    },
    {
      path: "/profile/addbank",
      name: "เพิ่มธนาคาร",
      icon: "ni ni-key-25 text-info",
      backButton:false,
      layout: MainLayout,
      component: AddฺBankView,
      navBar: MainNavbar
    },
    {
      path: "/Muayhistory",
      name: "รายการของฉัน",
      icon: "ni ni-key-25 text-info",
      backButton:false,
      layout: MainLayout,
      component: MuayMyOrderView,
    },
    {
      path:'/MuayOther',
      name: "มวยอื่นๆ",
      icon: "ni ni-key-25 text-info",
      backButton:false,
      layout: MainLayout,
      component: MuayOtherView,
    }
  ]
}
export default routes;
