import Config from "../config/config";
import axiosClient from './axios/axiosClient'
import AuthenService from './AuthenService'

export default class MuayPakYokService {
    constructor() { }

    static async getMyorderSummary(limit) {
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL + '/muay/myorder/summary/' + limit,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthenService.getAcessToken()
            },
        };
        const returnData = await axiosClient(config).then(async (response) => {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    }

    static async getResultMauyPakYok() {
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL + '/muay/history/datelist',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthenService.getAcessToken()
            },
        };
        const returnData = await axiosClient(config).then(async (response) => {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    }

    static async getMauyPakYokByDate(date) {
        var [day, month, year] = date.split('-')
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL + `/muay/history/data/${day}/${month}/${year}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthenService.getAcessToken()
            },
        };
        const returnData = await axiosClient(config).then(async (response) => {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    }

}