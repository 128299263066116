import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Avatar, Image, Typography, Empty, Skeleton } from 'antd';
import MuayPakYokService from '../../service/MuayPakYokService';
import noImage from '../../assets/img/no_img.png'
const ResultRoundFightComponent = () => {
    const [loading, setLoading] = useState(true)
    const [resultMauyPakYok, setResultMauyPakYok] = useState([])
    const [cardShow, setCardShow] = useState(false)
    const [mauyPakYokByDate, setMauyPakYokByDate] = useState([])

    useEffect(() => {
        MuayPakYokService.getResultMauyPakYok().then(res => {
            if (res.status) {
                setResultMauyPakYok(res.data)
                setLoading(false)
            }
        })
    }, [])

    const showMauyFight = (date) => {
        setLoading(true)
        MuayPakYokService.getMauyPakYokByDate(date).then(res => {
            if (res.status) {
                console.log(res.data)
                setMauyPakYokByDate(res.data)
                setCardShow(true)
                setLoading(false)
            }
        })
    }

    const hideMauyFight = () => {
        setCardShow(false)
    }

    const _styleWinner = (winner) => {
        let backgroundColor = '#75787d';
        switch (winner) {
            case 'RED':
                backgroundColor = 'red'
                break;
            case 'BLUE':
                backgroundColor = 'blue'
                break;
            case 'DRAW':
                backgroundColor = '#75787d'
                break;
            case 'CANCEL':
                backgroundColor = '#75787d'
                break;
            default:
                backgroundColor = '#75787d'
                break;
        }
        return {
            backgroundColor: backgroundColor, padding: '10px 0', color: '#FFF', borderRadius: '5px'
        }
    }

    const _textWinner = (winner, announceMsg) => {
        let text = '';
        switch (winner) {
            case 'RED':
                text = <div>แดง<br />ชนะ</div>
                break;
            case 'BLUE':
                text = <div>น้ำเงิน<br />ชนะ</div>
                break;
            case 'DRAW':
                text = 'เสมอ'
                break;
            case 'CANCEL':
                text = 'ยกเลิก'
                break;
            case 'FOUL':
                let _text = announceMsg.split('ไล่ลง')
                text = <div>ไล่ลง<br />{_text[1]}</div>
                break;
            default:
                text = ''
                break;
        }
        return text
    }

    return (
        <div>
            <Skeleton active loading={loading}>
                {/* Empty data */}
                {resultMauyPakYok.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                <div style={cardShow ? { display: 'none' } : { display: 'block' }}>
                    {
                        resultMauyPakYok?.map((item, index) => {
                            return (
                                <Col key={index} onClick={async () => showMauyFight(item.dateFight)} style={index % 2 == 1 ? { cursor: 'pointer', borderBottom: '2px solid #F3F3F3', padding: '15px 0px', fontWeight: 400, backgroundColor: '#F3F3F3' } : { cursor: 'pointer', borderBottom: '2px solid #F3F3F3', padding: '10px 0px', fontWeight: 400 }}>
                                    <center>วันที่ {item.dateFight}</center>
                                </Col>
                            )
                        })
                    }
                </div>

                {cardShow &&
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        {mauyPakYokByDate.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        {mauyPakYokByDate.map((item, key) =>
                            <Row key={key} style={{ borderBottom: '2px solid #F3F3F3', padding: '10px 0px', fontWeight: 400, alignItems: 'center' }}>
                                {/* Image red fighter */}
                                <Col span={4}>
                                    <Avatar style={{ border: "solid 3px red" }} shape="square" size={{ xs: 50, sm: 70, md: 80, lg: 100, xl: 100, xxl: 100 }} src={<Image src={item.redMan.picture || noImage} />} />
                                </Col>
                                {/* Image */}
                                <Col span={4}>
                                    <Avatar style={{ border: "solid 3px blue" }} shape="square" size={{ xs: 50, sm: 70, md: 80, lg: 100, xl: 100, xxl: 100 }} src={<Image src={item.blueMan.picture || noImage} />} />
                                </Col>
                                <Col span={12} >
                                    <Row>
                                        <div style={{ marginRight: '5px' }}>
                                            <div style={{ width: '6px', height: '50%', backgroundColor: 'red', borderTopLeftRadius: '3px', borderTopRightRadius: '3px' }}>
                                            </div>
                                            <div style={{ width: '6px', height: '50%', backgroundColor: 'blue', borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px' }}>
                                            </div>
                                        </div>

                                        {/* name fighter */}
                                        <div className={'detail-name-muay-pak-yok'} >
                                            <div>{item.redMan.firstName} {item.redMan.lastName}</div>
                                            <div>{item.blueMan.firstName} {item.blueMan.lastName}</div>
                                            <div>{new Date(item.dateFight).toLocaleDateString()}</div>
                                        </div>
                                    </Row>
                                </Col>

                                {/* result fight */}
                                <Col span={4} >
                                    <center style={_styleWinner(item.winner)}>
                                        {_textWinner(item.winner, item.announceMsg)}
                                    </center>
                                </Col>
                            </Row>
                        )}
                        <Button type='primary' style={{ marginTop: 10 }} onClick={() => { hideMauyFight() }}>กลับ</Button>
                    </div>
                }
            </Skeleton>
        </div>

    )
}

export default ResultRoundFightComponent