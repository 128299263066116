import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Tabs, Button, message } from 'antd';
import { CommentOutlined } from "@ant-design/icons";
import config from '../../config/config';
import UserService from '../../service/UserService';
import WalletService from '../../service/WalletService';
import DepositDetailComponent from '../../components/Contents/DepositDetailComponent';
import WidthDrawComponent from '../../components/Contents/WidthDrawComponent';
import CreditHistoryComponent from '../../components/Contents/CreditHistoryComponent';

export default class WalletView extends React.Component {
  constructor(props) {
    super(props);

    if (UserService.getMyUser().bankAccounts.length <= 0) this.props.history.push("/profile/addbank");
  }

  componentWillMount() {
    let msg = new URLSearchParams(this.props.location.search).get("msg");
    if (msg) {
      message.warning(msg);
    }
  }

  render() {
    return (<div className='wallet-view'>
      {UserService.getBankAccount() &&
        <Row bg-color='secondary' style={{ padding: 7, marginTop: -6, marginLeft: -10, marginRight: -10, color: '#FFF' }}>
          <Col span={15} style={{ display: "flex", justifyContent: 'flex-start', paddingLeft: 10 }}>
            <img src={WalletService.getBankData().image.src} height='100%' width='auto' style={{ borderRadius: 7, maxHeight: 40, marginRight: 10, alignSelf: 'center' }} />
            <span>
              {UserService.getBankAccount().accountNumber}<br />
              {UserService.getBankAccount().accountName}
            </span>
          </Col>
          <Col span={9} style={{ display: "flex", justifyContent: 'flex-end', paddingRight: 10 }}>
            <span style={{ alignSelf: 'center' }}><span style={{ color: '#888' }}>เครดิต</span> {parseInt(UserService.getWallet().credit)}</span>
          </Col>
        </Row>
      }
      <Tabs defaultActiveKey="1" centered>
        <Tabs.TabPane tab="ฝาก" key="1">
          <DepositDetailComponent />
        </Tabs.TabPane>
        <Tabs.TabPane tab="ถอน" key="2">
          <div style={{ color: 'red', width: '100%', maxWidth: '600px', margin: 'auto', marginTop: 20 }}>
            <WidthDrawComponent />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="ประวัติ" key="3">
          <CreditHistoryComponent />
        </Tabs.TabPane>
      </Tabs>
    </div>)
  }
}