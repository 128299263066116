import Config from "../config/config";
import axiosClient from './axios/axiosClient'
import AuthenService from './AuthenService'
import UserService from './UserService'
import scb_img from "../assets/img/bank/scb.svg";
import ktb_img from "../assets/img/bank/ktb.svg";
import bay_img from "../assets/img/bank/bay.svg";
import kbnk_img from "../assets/img/bank/kbnk.svg";
import tmb_img from "../assets/img/bank/tmb.svg";
import bbl_img from "../assets/img/bank/bbl.svg";
import gsb_img from "../assets/img/bank/gsb.svg";
import baac_img from "../assets/img/bank/baac.svg";
import ghba_img from "../assets/img/bank/ghba.svg";
import kkba_img from "../assets/img/bank/kkba.svg";
import tbnk_img from "../assets/img/bank/tbnk.svg";
import ttb_img from "../assets/img/bank/ttb.svg"

export default class WalletService {
    constructor() { }
    static bankAccountList = [
        {
            key: 'BAY',
            text: 'กรุงศรี',
            value: 'BAY',
            image: { avatar: true, src: bay_img },
        },
        {
            key: 'KTB',
            text: 'กรุงไทย',
            value: 'KTB',
            image: { avatar: true, src: ktb_img },
        },
        {
            key: 'KBNK',
            text: 'กสิกรไทย',
            value: 'KBNK',
            image: { avatar: true, src: kbnk_img },
        },
        {
            key: 'BBL',
            text: 'กรุงเทพ',
            value: 'BBL',
            image: { avatar: true, src: bbl_img },
        },
        {
            key: 'TMB',
            text: 'ทหารไทย',
            value: 'TMB',
            image: { avatar: true, src: tmb_img },
        },
        {
            key: 'GSBA',
            text: 'ออมสิน',
            value: 'GSBA',
            image: { avatar: true, src: gsb_img },
        },
        {
            key: 'TBNK',
            text: 'ธนชาต',
            value: 'TBNK',
            image: { avatar: true, src: tbnk_img },
        },
        {
            key: 'BAAC',
            text: 'ธกส.',
            value: 'BAAC',
            image: { avatar: true, src: baac_img },
        },
        {
            key: 'SCB',
            text: 'ไทยพาณิชย์',
            value: 'SCB',
            image: { avatar: true, src: scb_img },
        },
        {
            key: 'KKBA',
            text: 'เกียรตินาคิน',
            value: 'KKBA',
            image: { avatar: true, src: kkba_img },
        },
        {
            key: 'GHBA',
            text: 'อาคารสงเคราะห์',
            value: 'GHBA',
            image: { avatar: true, src: ghba_img },
        },
        {
            key: 'TTB',
            text: 'ทหารไทยธนชาต (TTB)',
            value: 'TTB',
            image: { avatar: true, src: ttb_img },
        },
    ]

    static getBankData(bankName = UserService.getBankAccount().bankName) {
        return this.bankAccountList.find(elm => elm.key == bankName)
    }

    static async withdraw(money) {
        if (money == null) return { status: false, msg: "money action is null" }
        var config = {
            method: 'post',
            url: Config.API_CONFIG.API_URL+'/banksystem/withdrawRequest',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthenService.getAcessToken()
            },
            data: JSON.stringify({ amount: money })
        };

        const returnData = await axiosClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        UserService.fetchMyUser()
        return await returnData
    }

    static async getDepositBank() {
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL + '/banksystem/getBankOfdeposit',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthenService.getAcessToken()
            },
        };

        const returnData = await axiosClient(config).then(function (response) {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    }

    static async getWallet(limit) {
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL + '/wallet/me/' + limit,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthenService.getAcessToken()
            },
        };
        const returnData = await axiosClient(config).then(async (response) => {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    }
}