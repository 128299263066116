import React from "react";
import { Avatar, Row, Col,PageHeader, Button, Anchor } from "antd";
import { UserOutlined,ArrowLeftOutlined } from "@ant-design/icons";
import Logo_img from "../../assets/img/logo.png";

class MainappNavbar extends React.Component {
  render() {
    return (
      <>
        <div>
          <Row style={{padding:"0vw 5vw"}}>
            <Col span={8}>
              {(this.props.enableBack | this.props.enableBack == true ) ? 
              <ArrowLeftOutlined onClick={(event) => window.history.back()} />
              : null }
            </Col>
            <Col span={8} style={{ textAlign: "center",fontWeight:200,fontSiz:"0.4rem"}}>
              {this.props.title}
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              {/* <Avatar size="large" icon={<UserOutlined />} /> */}
            </Col>
          </Row>
        </div>
        {/* <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="หน้าหลัก"
          extra={[
            <img src={Logo_img} alt="no img" width="50" height="auto" key="1" />
          ]}
        ></PageHeader> */}
      </>
    );
  }
}
export default MainappNavbar;
