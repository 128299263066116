import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Avatar, Row, Col } from "antd";
// core components
import MainNavbar from "../components/Navbars/MainNavbar";
import WalletNavber from "../components/Navbars/WalletNavber";
import MainTabBarComponent from "../components/Tabar/MainTabBarComponent";

import routes from "../routes/route";
const { Header, Content, Footer } = Layout;

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    // document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    // document.body.classList.remove("bg-default");
  }

  render() {
    return (
      <Layout className="layout AppBg1 container-fullscreen">
        <Header style={{ position: 'fixed', zIndex: 999, width: '100%' }}>
          {this.props.routeData.navBar ?
            <this.props.routeData.navBar title={this.props.routeData.name} enableBack={this.props.routeData.backButton}/> :
            <WalletNavber title={this.props.routeData.name} enableBack={this.props.routeData.backButton} />
          }
        </Header>
        <Content style={{ marginTop: 42, background: '#FFF' }}>
          <center>
            <div className="container">
              <Route component={this.props.routeData.component} />
            </div>
          </center>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          <MainTabBarComponent />
        </Footer>
      </Layout>
    );
  }
}

export default MainLayout;
