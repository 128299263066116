import React from "react";
import { Row, Col, Card, Button, Avatar, Image, Typography, Empty, Skeleton } from 'antd';
import io from 'socket.io-client';
import config from "../../config/config";
import Authen from "../../service/AuthenService";
const versusImg = require('../../assets/img/versus.png');

export default class RoundFightComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roundList: [],
            loading: true
        }
    }

    async componentDidMount() {
        //socket init
        this.socket = await io.connect(config.API_CONFIG.SOCKET_URL + "/muayround", {transports: ["websocket"], query: { token: Authen.getAcessToken() } })
        this.socket.emit('MUAY_PLAYLIST')
        this.socket.on('MUAY_PLAYLIST', data => {
            if (data.status) {
                this.setState({ roundList: data.data, loading: false })
            } else {
                console.log(data.msg)
            }
        })
    }

    componentWillUnmount() {
        this.socket.disconnect()
    }

    render() {
        return (
            <div>
                <Skeleton active loading={this.state.loading}>
                    {this.state.roundList.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    {this.state.roundList.map((round, key) =>
                        <Row key={key} style={{ marginBottom: 10 }}>
                            <Card
                                className="card-playlist"
                                hoverable
                                bg-color='secondary'
                                actions={[
                                    <Button type='primary' onClick={() => this.props.props.history.push(`/home/muayplay/${round._id}`)} color='green' disabled={round.status == 2 ? false : true} block >{round.status == 2 ? 'เข้าเล่น' : 'รอเปิดแทง'}</Button>
                                ]}
                            >
                                <center>
                                    <Row>
                                        {round.liveBroadcast && <Col span={24}><span style={{color:'#f5222d'}}>🔴 ถ่ายทอดสด</span></Col>}
                                        <Col span={9}>
                                            <Avatar style={{ border: "solid 3px red", marginBottom: 3 }} size={{ xs: 70, sm: 90, md: 120, lg: 130, xl: 160, xxl: 160 }} src={<Image src={round.redMan.picture} />} />
                                            <p>
                                                <Typography.Text level={4} type="danger" strong>{round.redMan.firstName}</Typography.Text><br />
                                                <Typography.Text level={5} type="danger" style={{ margin: 0 }}>{round.redMan.lastName}</Typography.Text>
                                            </p>
                                        </Col>
                                        <Col span={6} style={{ display: 'flex' }}>
                                            <img src={versusImg} style={{ alignSelf: 'center' }} width="100%" height="auto" />
                                        </Col>
                                        <Col span={9}>
                                            <Avatar style={{ border: "solid 3px blue", marginBottom: 3 }} size={{ xs: 70, sm: 90, md: 120, lg: 130, xl: 160, xxl: 160 }} src={<Image src={round.blueMan.picture} />} />
                                            <p>
                                                <Typography.Text level={4} style={{ color: '#a7a7ff' }} strong>{round.blueMan.firstName}</Typography.Text><br />
                                                <Typography.Text level={5} style={{ margin: 0, color: '#a7a7ff' }}>{round.blueMan.lastName}</Typography.Text>
                                            </p>
                                        </Col>
                                    </Row>
                                </center>
                            </Card>
                        </Row>
                    )}
                </Skeleton>
            </div>
        )
    };

};