const dotenv = require('dotenv');

export default {
    API_CONFIG:{
        API_URL : process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
        SOCKET_URL: process.env.REACT_APP_SOCKET_URL || 'http://localhost:3002',
        BANK_SERVIE_API_URL:  process.env.REACT_APP_BANK_SERVIE_API_URL || 'http://localhost:3003',
    },
    LINE_LIFF_ID : process.env.REACT_APP_LINE_LIFF_ID,
    HOSTNAME: process.env.REACT_APP_HOSTNAME,
    BACKEND_APP_SECRET : process.env.REACT_APP_BACKEND_APP_SECRET || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZjE4NjRiZDQxYWQyOTRmMTgxMDU0Y2EiLCJyb2xlIjoiQURNSU4iLCJpYXQiOjE1OTU0MzUxNjJ9.4HEP_gaKy7nmRbwLMwlLSzS2_au5vdDtsigr8T_0ouo',
    CONTACT_US_LINK: process.env.REACT_APP_CONTACT_US_LINK || 'https://page.line.me/833ammlm',
}
