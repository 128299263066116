import Config from "../config/config";
import axiosClient from './axios/axiosClient'
import AuthenService from './AuthenService'

export default class UserService {
    constructor() {}

    static getMyUser() {
        return JSON.parse(localStorage.getItem("user"))
    }

    static getWallet() {
        return JSON.parse(localStorage.getItem("user")).wallet
    }

    static async fetchMyUser() {
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL+'/auth/me',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+AuthenService.getAcessToken()
            },
        };
        const returnData = await axiosClient(config).then(async(response)=>{
            localStorage.setItem('user', JSON.stringify(response.data))
            return { status: true }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    }

    static async saveBankAccount(bankName, accountNumber, accountName,tel) {
        var config = {
            method: 'post',
            url: Config.API_CONFIG.API_URL+'/user/bank',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthenService.getAcessToken(),
            },
            data: JSON.stringify({ accountNumber, accountName, bankName,tel })
        };

        const returnData = await axiosClient(config).then(function (response) {
            localStorage.setItem('user', JSON.stringify(response.data))
            return { status: true, data: response.data.account }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    }

    static getBankAccount() {
        const bank = JSON.parse(localStorage.getItem('user')).bankAccounts.filter((item, key)=>item.isEnabled)
        if (bank.length <= 0) {
            return null
        }
        return bank[0]
    }
}