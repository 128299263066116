import React from "react";
import { Row, Col,Card,Button,message,Image,Typography,Avatar,Skeleton, List } from 'antd';
import {CheckCircleFilled,SettingOutlined} from "@ant-design/icons";
import WalletService from "../../service/WalletService";
import ConfigValueService from "../../service/ConfigValueService";
import UserService from "../../service/UserService";

export default class CreditHistoryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      initLoading:true,
      loading:false,
      transactionList:[],
      limitShow:10
    }
  }

  componentDidMount() {
    UserService.fetchMyUser();
    this.loadTransactionList().then(()=>this.setState({initLoading:false}))
  }

  async loadTransactionList(){
    this.setState({loading:true})
    const creditHistory=(await WalletService.getWallet(this.state.limitShow)).data?.creditHistory || []
    this.setState({transactionList:creditHistory, loading:false})
  }

  getTextTransactionType(type){
      let text=""
      switch(type) {
        case 1:
            text="ฝากเงิน"
            break;
        case 2:
            text="ถอนเงิน"
            break;
        case 3:
            text="แทงมวย"
            break;
        case 4:
            text="ชนะมวย"
            break;
        case 5:
            text="รับเงินคืน"
            break;
        case 6:
            text="ดึงเงินคืน"
            break;
        case 7:
          text="ฝากเครดิตโดย Admin"
          break;
        case 8:
          text="ดึงเครดิตคืนโดย Admin"
          break;
        default:
          text="รายการการเงิน"
      }
      return text
  }
  
  render(){return (
    <div className='CreditHistoryComponent'>
        <Skeleton active loading={this.state.initLoading}>
        <List
            itemLayout="horizontal"
            dataSource={this.state.transactionList}
            loadMore={<center>{this.state.transactionList.length>=this.state.limitShow&&<Button onClick={()=>{this.state.limitShow+=5;this.loadTransactionList()}} >ดูเพิ่มเติม</Button>}</center>}
            style={{padding:"3px 0px"}}
            renderItem={item => (
                <Skeleton active loading={this.state.loading}>
                    <List.Item style={{padding:"10px 10px",borderBottom:"1px solid #CCC"}} actions={[<Typography.Text strong type={item.isIncome?'success':'danger'}>{item.isIncome?'+':'-'} {Number(item.actionValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography.Text>]}>
                        <List.Item.Meta
                            title={this.getTextTransactionType(item.type)}
                            description={new Date(item.createdAt).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }) + " " + new Date(item.createdAt).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}
                        />
                    </List.Item>
                </Skeleton>
            )}
        />
        </Skeleton>
    </div>
  )};
  
};