import React from "react";
import { Tabs, } from 'antd';

import RoundFightComponent from '../../components/Contents/RoundFightComponent';
import ResultRoundFightComponent from '../../components/Contents/ResultRoundFightComponent';

const HomeView = (props) => {
  const { TabPane } = Tabs;

  function callback(key) {
    // console.log(key);
  }

  return (

    <div className='muay-home'>
      <Tabs defaultActiveKey="1" onChange={callback} centered>
        <TabPane tab="มวยพักยก" key="1">
          <RoundFightComponent props={props} />
        </TabPane>
        <TabPane tab="ผลการชก" key="2">
          <ResultRoundFightComponent />
        </TabPane>
      </Tabs>
    </div>

  )
}

export default HomeView