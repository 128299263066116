import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Avatar,Row, Col} from "antd";
import { UserOutlined } from "@ant-design/icons";
// core components
// import AuthNavbar from "components/Navbars/AuthNavbar.js";
// import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "../routes/route";
const { Header, Content, Footer } = Layout;

class Auth extends React.Component {
  componentDidMount() {
    // document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    // document.body.classList.remove("bg-default");
  }

  render() {
    return (
      <Layout className="layout AppBg1-blur">
        <Content>
            <Switch>
              <Route component={this.props.routeData.component}/>
            </Switch>
        </Content>
      </Layout>
    );
  }
}

export default Auth;
