import Config from "../config/config";
import axiosClient from './axios/axiosClient'
import liff from '@line/liff';

export default class AuthenService {
    constructor() {
    }

    static async signin(idToken, agentId="", tel="") {
        var config = {
            method: 'post',
            url: Config.API_CONFIG.API_URL + '/auth/signin',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                idToken: idToken,
                agentId: agentId,
                tel : tel
            }
        };
        const returnData = await axiosClient(config).then(async(response)=>{
            await liff.init({ liffId: Config.LINE_LIFF_ID }).catch(err=>console.log(err));
            let lineProfile = await liff.getProfile();
            localStorage.setItem('login', true);
            localStorage.setItem('user', JSON.stringify(response.data.user))
            localStorage.setItem('userImg',lineProfile.pictureUrl)
            setAcessToken(response.data.token)
            return { status: true }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    }

    static async logout() {
        return await new Promise(async (resolve) => {
            await liff.init({ liffId: Config.LINE_LIFF_ID }).catch(err=>console.log(err));
            await liff.logout();
            localStorage.removeItem("userImg")
            localStorage.removeItem("login")
            localStorage.removeItem("user")
            localStorage.removeItem("AcessToken")
            resolve('pass')
        })
    }

    static getAcessToken() {
        let acessToken = localStorage.getItem('AcessToken')
        return acessToken
    }
}

const setAcessToken = (token) => {
    localStorage.setItem('AcessToken', token)
}