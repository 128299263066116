import React, { useEffect, useState } from "react";
import { Skeleton, Row, Col, Button, Empty, Collapse, message } from 'antd';
import MuayStepService from '../../service/MuayStepService';

export default () => {
    const [loading, setLoading] = useState(true)
    const [myorderList, setMyorderList] = useState([])
    const [limitOrderList, setLimitOrderList] = useState(7)

    useEffect(() => {
        setLoading(true)
        window.scrollTo(0, 0)
        MuayStepService.getMuayStepOrder(limitOrderList).then(res => {
            if (res.status) {
                setMyorderList(res.data)
            } else {
                message.error("Load data is fail!")
            }
            setLoading(false)
        })
    }, [limitOrderList])

    function callback(key) {
        // console.log(key);
    }

    return (
        <div>
            <Skeleton active loading={loading}>
                {/* Content list muay history */}
                <Row className='header-muay-pakyok-title' align='middle'>
                    <Col span={16} style={{ justifyContent: 'center' }}>รายการเดิมพัน</Col>
                    <Col span={4}>ได้เสีย</Col>
                    <Col span={4}>ผล</Col>
                </Row>
                {myorderList.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                {/* data muay history */}
                {myorderList.map((order, key) =>
                    <Collapse className='custom-collapse-step' key={key} collapsible="header">
                        <Collapse.Panel header={HeaderC(order, key)}>
                            <Row align='middle' className='header-muay-step'>
                                <Col span={16} >
                                    <center>รายการเดิมพัน</center>
                                </Col>
                                <Col span={4}><center>ราคาคูณ</center></Col>
                                <Col span={4}><center>สถานะ</center></Col>
                            </Row>

                            {/* list play muay step */}
                            {order.items.map((Oitem, Okey) =>
                                <Row align='middle' key={Okey + key} className='list-muay-step'>
                                    <Col span={16} style={{ paddingTop: 3, paddingBottom: 3 }}>
                                        {Oitem.shoot == 'RED' ? <>
                                            <span style={{ color: 'red', fontWeight: "600", fontSize: "0.85rem" }}><span style={{ color: 'black' }}>@ </span> {`${Oitem.redMan.firstName} ${Oitem.redMan.lastName}`}</span><br />
                                            <span style={{ color: 'blue' }}>vs {`${Oitem.blueMan.firstName} ${Oitem.blueMan.lastName}`}</span>
                                        </>
                                            : <>
                                                <span style={{ color: 'red' }}>vs {`${Oitem.redMan.firstName} ${Oitem.redMan.lastName}`}</span><br />
                                                <span style={{ color: 'blue', fontWeight: "600", fontSize: "0.85rem" }}><span style={{ color: 'black' }}>@ </span> {`${Oitem.blueMan.firstName} ${Oitem.blueMan.lastName}`}</span>
                                            </>}
                                    </Col>
                                    <Col span={4}><center style={{ fontSize: 16, fontWeight: 400 }}>{Oitem.multiply}</center></Col>
                                    <Col span={4} style={{ borderRadius: 7, backgroundColor: "#EEE", padding: "5px 0px" }}>
                                        <center>
                                            {Oitem.status == 0 &&
                                                <div style={{ color: '#ffc107' }}>รอ</div>
                                            }
                                            {Oitem.status == 1 &&
                                                ((Oitem.isWin == 1) ? <div style={{ color: '#00BF2A ' }}>ชนะ</div>
                                                    : (Oitem.isWin == 2) ? <div style={{ color: '#AAA ' }}>เสมอ</div>
                                                        : (Oitem.isWin == 0) && <div style={{ color: '#DB0000 ' }}>แพ้</div>)
                                            }
                                            {Oitem.status === 2 &&
                                                <div style={{ color: '#AAA' }}>ยกเลิกชก</div>
                                            }
                                        </center>
                                    </Col>
                                </Row>
                            )}

                            <Row align='middle' className='result-muay-step'>
                                <Col span={12} >
                                    <div style={{ fontWeight: 400 }}>ราคาคูณสูงสุด</div>
                                    <div style={{ fontWeight: 400 }}>ยอดได้สูงสุด</div>
                                    <div style={{ fontWeight: 400 }}>เงินเดิมพัน</div>
                                </Col>
                                <Col span={12}>
                                    <div style={{ fontWeight: 400, textAlign: 'right' }}>x{order.orderMultipy || ""}</div>
                                    <div style={{ fontWeight: 400, textAlign: 'right' }}>{(order.playPrice.lossPrice + order.playPrice.preWinPrice) || ""}</div>
                                    <div style={{ fontWeight: 400, textAlign: 'right' }}>{order.shootPrice}</div>
                                </Col>
                            </Row>
                        </Collapse.Panel>
                    </Collapse>
                )}

                {myorderList.length >= limitOrderList &&
                    <center>
                        <Button type='primary' style={{ marginTop: 10 }} onClick={() => { setLimitOrderList(limitOrderList + 5) }}>เพิ่มเติม</Button>
                    </center>
                }
            </Skeleton>
        </div>
    )
}

const HeaderC = ({ status, isWin, shootPrice, playPrice, createdAt }, key) => (
    <Row className='header-muay-pakyok' align='middle'>
        {/* shoot muay name detail */}
        <Col span={16} style={{ justifyContent: 'left', paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
            <div style={{ color: 'rgba(77, 77, 77, 1)', fontWeight: "700", fontSize: "1rem", paddingRight: 10 }}>
                {key + 1}
            </div>
            <div className={'shot-name-detail'}>
                <div style={{ color: 'rgba(77, 77, 77, 1)', fontWeight: "700", fontSize: "0.85rem" }}>🕒 {new Date(createdAt).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })} {new Date(createdAt).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}</div>
                <div style={{ color: 'rgba(77, 77, 77, 1)' }}>แทง {shootPrice} บาท</div>
            </div>
        </Col>

        {/* shoot price */}
        <Col span={4}>
            {status == 1 || status == 2 ?//ออกผลแล้ว
                (playPrice.winPrice - playPrice.lossPrice) >= 0 ?
                    <div style={{ color: playPrice.winPrice == playPrice.lossPrice ? '#555' : '#00BF2A' }}>+{(playPrice.winPrice).toFixed(2)}</div>
                    :
                    <div style={{ color: 'red' }}>{playPrice.winPrice}</div>
                : ""}
        </Col>

        {/* price win lose */}
        <Col span={4}>
            {status == 0 ? <div style={{ color: '#ffc107' }}>รอออกผล</div>
                : status == 1 ? isWin ? <div style={{ color: '#00BF2A' }}>ชนะ</div> : <div style={{ color: '#DB0000' }}>แพ้</div>
                    : status == 2 && <div style={{ color: '#AAA' }}>ยกเลิกโพย</div>
            }
        </Col>
    </Row>
)