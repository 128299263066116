import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { Row, Col,Result,Form,Steps,Card,Button,Input,message,Space } from 'antd';
import {EnvironmentFilled,SaveFilled} from "@ant-design/icons";
import UserService from '../../service/UserService';
import WalletService from '../../service/WalletService';

class AddฺBankView extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      stepActive:0,
      bankName:"",
    }

    if(UserService.getMyUser().bankAccounts.length>0)this.props.history.push("/profile");
  }

  stepsList = [
    {
      title: 'เลือกธนาคาร',
      content: <SelectBankNameComponent onSelected={()=>this.setState({stepActive:1})} setActiveBank={(value)=>this.setState({bankName:value})}/>,
    },
    {
      title: 'ข้อมูล',
      content: <SaveBankAccount bank={()=>this.state.bankName} onEditBank={()=>this.setState({stepActive:0})} onSaved={()=>this.hendalOnsaved()}/>,
    },
    {
      title: 'สำเร็จ',
      content: <Result status="success" title="เพิ่มบัญชีสำเร็จ" />
    },
  ];
  
  hendalOnsaved(){
    this.setState({stepActive:2})
    setTimeout(()=>{
      this.props.history.push("/profile");
    },1250)
  }

  render(){
      return(
          <>
          <Steps current={this.state.stepActive} style={{marginBottom:20}} size='small'>
              {this.stepsList.map(item => (
                <Steps.Step key={item.title} title={item.title} />
              ))}
          </Steps>
          <div>{this.stepsList[this.state.stepActive].content}</div>
          </>
      )
  }
}

const SelectBankNameComponent = (props) => {
  const [activeBank, setActiveBank] = useState(props.defaultActiceBank || "");
  useEffect(() => {

  },[activeBank]);

  const selectBank=(bank)=>{
    setActiveBank(bank)
    props.setActiveBank(bank)
    props.onSelected()
  }

  return (<>
    <Row gutter={[16,16]}>
      {WalletService.bankAccountList.map((item,key)=>
      <Col span={8}>
        <Card
          key={key}
          bordered={false}
          hoverable
          style={{ width: '100%',backgroundColor:'transparent', borderRadius:10, padding:5 }}
          bg-color={item.key==activeBank && 'gray'}
          cover={<img src={item.image.src} width='100%' height='auto' style={{borderRadius:10}} />}
          onClick={()=>selectBank(item.key)}
        >
          <Card.Meta style={{textAlign:'center'}} description={item.text} />
        </Card>
      </Col>      
      )}
    </Row>
  </>)
}

const SaveBankAccount=(props)=>{
  const [form] = Form.useForm();
  const [accountNumber,setAccountNumber] = useState("")
  const [accountName,setAaccountName] = useState("")
  const [tel,setTel] = useState("")
  const [saveLoading,setSaveLoading] = useState(false)

  const onSaveBankAccount=()=>{
    setSaveLoading(true)
    if(props.bank() && accountNumber && accountName && tel){
      UserService.saveBankAccount(props.bank(),accountNumber,accountName,tel).then(res=>{
        if(res.status){
          props.onSaved()
          message.success("บันทึกสำเร็จ")
        }else{
          message.error(res.msg)
        }
        setSaveLoading(false)
      })
    }else{
      message.warning("บันทึกไม่สำเร็จ! ข้อมูลไม่ถูกต้อง")
    }
  }

  return(<>
     <Form
        layout='vertical'
        form={form}
        onFinish={(value)=>onSaveBankAccount()}
        wrapperCol= {{
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 14,
            offset: 5
          }
        }}
        labelCol= {{
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 14,
            offset: 5
          }
        }}
      >
        <Form.Item>
          <Row onClick={props.onEditBank} style={{cursor:'pointer'}}>
            <Space bg-color='gray' style={{width:'100%',borderRadius:10}}>
                <img src={WalletService.getBankData(props.bank()).image.src} width='auto' height='80' style={{borderRadius:10}} />
                <p>
                  <span style={{fontSize:'1.1rem',fontWeight:'bold'}}>ธนาคาร{WalletService.getBankData(props.bank()).text}</span><br/>
                  {WalletService.getBankData(props.bank()).key}
                </p>
            </Space>
          </Row>
        </Form.Item>
        <Form.Item label="เลขที่บัญชี" name='accountNumber' rules={[{required:true,message:'กรอกเลขที่บัญชี',pattern:'[0-9]{7}'}]}>
          <Input placeholder="input acount number" value={accountNumber} onChange={(event)=>setAccountNumber(event.target.value)} />
        </Form.Item>
        <Form.Item label="ชื่อบัญชี" name='accountName' rules={[{required:true,message:'กรอกชื่อบัญชี'}]}>
          <Input placeholder="input account name" value={accountName} onChange={(event)=>setAaccountName(event.target.value)} />
        </Form.Item>
        <Form.Item label="เบอร์โทรศัพท์" name='tel' rules={[{required:true,message:'กรอกเบอร์โทรศัพท์',pattern:'[0-9]{10}',max:10}]}>
          <Input placeholder="input your phone number" value={tel} onChange={(event)=>setTel(event.target.value)} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<SaveFilled/>} loading={saveLoading}>บันทึก</Button>
        </Form.Item>
      </Form>
  </>)
}

export default AddฺBankView
export {SelectBankNameComponent,SaveBankAccount}