import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Avatar,Row, Col} from "antd";
// core components
import WalletNavber from "../components/Navbars/WalletNavber";
import MainTabBarComponent from "../components/Tabar/MainTabBarComponent";
const { Header, Content, Footer } = Layout;

class PlayGameLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.enableFooter=this.props.routeData.enableFooter==undefined ? true : this.props.routeData.enableFooter
  }
  
  componentDidMount() {
  }
  componentWillUnmount() {
  }
  
  render() {
    return (
      <Layout className="layout AppBg1 container-fullscreen">
        <Header style={{ position: 'fixed', zIndex: 999, width: '100%' }}>
          <WalletNavber title={this.props.routeData.name} enableBack={this.props.routeData.backButton}/>
        </Header>
        <Content style={{marginTop: 42,paddingBottom: this.enableFooter?50:0}}>
            <Route component={this.props.routeData.component}/>
        </Content>
        {this.enableFooter&& 
        <Footer style={{ textAlign: "center" }}>
          <MainTabBarComponent/>
        </Footer>
        }
      </Layout>
    );
  }
}

export default PlayGameLayout;
