import React, { useEffect, useState } from "react";
import { Skeleton, Row, Col, Empty, Button, Input, Modal } from 'antd';
import {
    CheckCircleFilled,
    ShoppingCartOutlined,
    DeleteFilled
} from "@ant-design/icons";
import MuayStepService from '../../service/MuayStepService';

const MuayStepFightComponent = () => {
    const [listSelect, setListSelect] = useState([])
    const [muayStepList, setMuayStepList] = useState([])
    const [loading, setLoading] = useState(true)
    const [modalVisible, setModalVisible] = useState(false)
    const [playLoading, setPlayLoading] = useState(false)
    const [shootPrice, setShootPrice] = useState('')
    const [shootMultiply, setShootMultiply] = useState('')
    const [idChange, setIdChange] = useState([])
    const [muayStepListObj, setMuayStepListObj] = useState([])
    const [isOpenModalRules, setIsOpenModalRules] = useState(false)

    useEffect(() => {
        const getMuayStepList = async () => {
            let res = await MuayStepService.getMuayStepList()
            await setMuayStepList(res.data)
            let stepList = groupMuayStep(res.data, 'titleRound')
            await setMuayStepListObj(stepList)
            setLoading(false)
        }
        getMuayStepList()
    }, [loading])

    const checkStatus = (data) => {
        let index_ = listSelect.findIndex(list => list._id === data._id);
        if (index_ >= 0) {
            return listSelect[index_].shoot
        } else {
            return false
        }
    }

    const checkListSelect = (data) => {
        let index_ = listSelect.findIndex(list => list._id === data._id);
        if (index_ >= 0) {
            if (listSelect[index_].shoot == data.shoot) {
                let listSelect_temp = listSelect
                listSelect_temp.splice(index_, 1)
                calShootMutiply(listSelect_temp)
                setListSelect([...listSelect_temp])
            } else {
                let listSelect_temp = listSelect
                listSelect_temp[index_].shoot = data.shoot
                listSelect_temp[index_].multiply = data.multiply
                calShootMutiply(listSelect_temp)
                setListSelect([...listSelect_temp])
            }
        } else {
            calShootMutiply([...listSelect, data])
            setListSelect([...listSelect, data])
        }
    }

    const deleteSelectMuay = (data) => {
        let index_ = listSelect.findIndex(list => list._id === data._id)
        let listSelect_temp = listSelect
        listSelect_temp.splice(index_, 1)
        calShootMutiply(listSelect_temp)
        setListSelect([...listSelect_temp])

    }
    const handalInputShootPrice = (event) => {
        setShootPrice(parseInt(Number(event.target.value)))
    }

    const calShootMutiply = async (data) => {
        let shootMultiply = 1
        await data.map((item) => {
            shootMultiply = shootMultiply * item.multiply
        })
        setShootMultiply(shootMultiply)
    }

    const closeModal = () => {
        setIdChange([])
        setLoading(true)
        setListSelect([])
        setShootPrice('')
        setModalVisible(false)
    }

    const toTwoDecimal = (num) => Number(Number(num).toFixed(2))

    const toThreeDecimal = (num) => {
        let _num = num.toString().split('.')
        if (_num.length > 1) {
            return Number(_num[0] + '.' + _num[1].substring(0, 3))
        } else {
            return Number(_num)
        }
    }


    const submitPlayMuayStep = async () => {

        setPlayLoading(true)
        if (shootPrice != '' || shootPrice != 0) {
            let orderItem = []
            await listSelect.map((item) => {
                orderItem = [...orderItem, {
                    roundId: item._id,
                    playRateId: item.playRateStep._id,
                    shoot: item.shoot,
                    multiply: item.multiply
                }]
            })
            let res = await MuayStepService.playMuayStep({ price: shootPrice, orderItem })
            if (res.status) {
                Modal.success({
                    content: 'เดิมพันสำเร็จ',
                    onOk() {
                        closeModal()
                    }
                });
                setPlayLoading(false)
            } else {
                Modal.error({
                    title: 'เดิมพันไม่สำเร็จ',
                    content: res.msg,
                    onOk() {
                        getNewMuayStepList()
                    }
                });
                setPlayLoading(false)
            }
        } else {
            Modal.info({
                title: 'กรุณากรอกจำนวนเงิน',
                content: 'กรุณากรอกจำนวนเงิน',

            });
            setPlayLoading(false)
        }

    }

    const showConfirmPlay = () => {
        Modal.confirm({
            title: 'ยืนยันรายการเดิมพัน',
            content: 'ยืนยันการแทงเดิมพัน',
            onOk() { submitPlayMuayStep() },
            onCancel() { console.log('Cancel play') }
        });
    }

    const getNewMuayStepList = async () => {
        let res = await MuayStepService.getMuayStepList()
        calNewMultiply(res.data)
    }

    const calNewMultiply = async (data) => {
        let listSelect_temp = listSelect
        let _idChange = []
        await listSelect.map(async (item, index) => {
            let _index = await data.findIndex((newItem) => newItem._id == item._id)
            let _id_filter = await data.filter((newItem) => newItem._id == item._id)
            if (!!_id_filter && _index >= 0) {
                if (item.shoot === 'RED') {
                    if (data[_index].playRateStep.red !== item.multiply) {
                        listSelect_temp[_index].multiply = data[_index].playRateStep.red
                        _idChange = [..._idChange, _id_filter[0]._id]
                    }
                }
                if (item.shoot === 'BLUE') {
                    if (data[_index].playRateStep.blue !== item.multiply) {
                        listSelect_temp[_index].multiply = data[_index].playRateStep.blue
                        _idChange = [..._idChange, _id_filter[0]._id]
                    }
                }
            }
            if (listSelect.length - 1 === index) {
                setListSelect([...listSelect_temp])
                setIdChange([..._idChange])
            }
        })
    }

    const checkRateChange = (id) => {
        let result = idChange.filter((item) => item == id)
        return result?.length > 0
    }

    const groupMuayStep = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };

    const renderModal = () => {
        return (
            <div style={modalVisible ? {
                display: 'block',
                backgroundColor: '#FFFFFF',
                position: 'fixed',
                zIndex: 999,
                width: '100vw',
                height: '100vh',
                top: 0,
                left: 0,
                overflow: 'auto'
            } : { display: 'none' }}>

                <center>

                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', maxWidth: 1000 }}>
                        <span style={{ fontSize: 24, fontWeight: 400, marginBottom: 30, marginTop: 30 }}>ยืนยันการแทง</span>
                        {
                            listSelect?.map((item) => {
                                return (
                                    <Row align='middle' style={{ width: '100%', padding: '10px 20px', borderBottom: '2px solid #F2F2F2' }} key={item._id}>
                                        <Col span={16} >
                                            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', fontSize: 12, fontWeight: 400 }}>
                                                {item.shoot == 'RED' ? <>
                                                    <span style={{ color: 'red', fontWeight: "400", fontSize: "0.9rem" }}><span style={{ color: 'black' }}>แทง </span> {`${item.redMan.firstName} ${item.redMan.lastName}`}</span>
                                                    <span style={{ color: 'blue' }}>vs {`${item.blueMan.firstName} ${item.blueMan.lastName}`}</span>
                                                </>
                                                    : <>
                                                        <span style={{ color: 'red' }}>vs {`${item.redMan.firstName} ${item.redMan.lastName}`}</span>
                                                        <span style={{ color: 'blue', fontWeight: "400", fontSize: "0.9rem" }}><span style={{ color: 'black' }}>แทง </span> {`${item.blueMan.firstName} ${item.blueMan.lastName}`}</span>
                                                    </>}
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            {
                                                checkRateChange(item._id) ?
                                                    <center style={{ fontSize: 18, fontWeight: 400, color: 'red' }}>
                                                        {item.multiply}
                                                    </center>
                                                    :
                                                    <center style={{ fontSize: 18, fontWeight: 400 }}>
                                                        {item.multiply}
                                                    </center>
                                            }
                                        </Col>
                                        {!playLoading &&
                                            <Col span={4} onClick={() => deleteSelectMuay(item)}><center style={{ backgroundColor: '#F2F2F2', padding: '10px 0px', borderRadius: 10 }}><DeleteFilled style={{ color: 'red' }} /></center></Col>
                                        }
                                    </Row>
                                )
                            })
                        }

                        <div style={{ backgroundColor: "#DDD", width: '100%', padding: 15 }}>
                            <div style={{ width: '100%', fontWeight: 400, marginBottom: 20 }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <span>รวม</span>
                                    <span>บาท</span>
                                </div>
                                <Input type='number' placeholder="ไส่ราคาเดิมพัน" suffix="฿" value={shootPrice > 0 ? shootPrice : ""} onChange={handalInputShootPrice} />
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <span>ตัวคูณ</span>
                                    <span>x{toThreeDecimal(shootMultiply)}</span>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <span>ยอดได้</span>
                                    <span>{toTwoDecimal(shootPrice * shootMultiply)}</span>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '100%' }}>

                                <Button
                                    style={{
                                        width: '50%',
                                        margin: '0 10px',
                                        backgroundColor: '#777777',
                                        color: '#FFFFFF',
                                        height: '40px',
                                        borderRadius: '10px',
                                    }}
                                    onClick={closeModal} >ยกเลิก</Button>

                                <Button
                                    disabled={listSelect.length < 1}
                                    loading={playLoading}
                                    style={{
                                        width: '50%',
                                        margin: '0 10px',
                                        height: '40px',
                                        borderRadius: '10px',
                                    }}
                                    type='primary'
                                    color='green'
                                    onClick={showConfirmPlay} >ยืนยัน</Button>
                            </div>
                        </div>
                    </div>

                </center>

            </div>
        )
    }

    return (
        <>
            {renderModal()}
            <div style={{display: 'flex', flexDirection: 'row-reverse', paddingRight:15}}>
                <Button danger size='small' color='' onClick={()=>setIsOpenModalRules(true)}>กติกา</Button>
            </div>
            <Skeleton active loading={loading}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {muayStepList?.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    {
                        Object.entries(muayStepListObj).map(([key, value], i) => {
                            return (
                                <>
                                    <center style={{ color: 'red', fontSize: '1rem', fontWeight: 400, marginBottom: -10 }}>{key}</center>
                                    {
                                        value.map((item) => {
                                            return (
                                                <div style={{ width: '90%', margin: '10px 0' }} key={item._id}>
                                                    {/* <center style={{ color: 'red', fontSize: 11, fontWeight: 400 }}>{item.titleRound}</center> */}
                                                    <Row style={{
                                                        width: '100%',
                                                        backgroundColor: 'red',
                                                        color: 'white',
                                                        height: '40px',
                                                        border: 'unset',
                                                        borderRadius: '10px 10px 0px 0px',
                                                        fontWeight: 400,
                                                        fontSize: 14,
                                                        cursor: 'pointer'
                                                    }} align="middle" onClick={() => {
                                                        if (item.isActivePlayStep) {
                                                            checkListSelect({ ...item, ...{ shoot: 'RED', multiply: item.playRateStep.red } })
                                                        }
                                                    }}>
                                                        <Col span={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                                            {
                                                                checkStatus(item) == 'RED' &&
                                                                <CheckCircleFilled style={{
                                                                    color: '#008000',
                                                                    backgroundColor: 'white',
                                                                    borderRadius: 10
                                                                }} />
                                                            }
                                                        </Col>
                                                        <Col span={18}>
                                                            {`${item.redMan.firstName} ${item.redMan.lastName}`}
                                                        </Col>
                                                        <Col span={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                                            {
                                                                item.isActivePlayStep ?
                                                                    item.playRateStep.red
                                                                    :
                                                                    <span style={{ fontSize: 12 }}>
                                                                        พักเล่น
                                                                    </span>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row style={{
                                                        width: '100%',
                                                        backgroundColor: 'blue',
                                                        color: 'white',
                                                        height: '40px',
                                                        border: 'unset',
                                                        borderRadius: '0px 0px 10px 10px',
                                                        fontWeight: 400,
                                                        fontSize: 14,
                                                        cursor: 'pointer'
                                                    }} align="middle" onClick={() => {

                                                        if (item.isActivePlayStep) {
                                                            checkListSelect({ ...item, ...{ shoot: 'BLUE', multiply: item.playRateStep.blue } })
                                                        }
                                                    }}>
                                                        <Col span={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                                            {
                                                                checkStatus(item) == 'BLUE' &&
                                                                <CheckCircleFilled style={{
                                                                    color: '#008000',
                                                                    backgroundColor: 'white',
                                                                    borderRadius: 10
                                                                }} />
                                                            }
                                                        </Col>
                                                        <Col span={18}>
                                                            {`${item.blueMan.firstName} ${item.blueMan.lastName}`}
                                                        </Col>
                                                        <Col span={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                                            {
                                                                item.isActivePlayStep ?
                                                                    item.playRateStep.blue
                                                                    :
                                                                    <span style={{ fontSize: 12 }}>
                                                                        พักเล่น
                                                                    </span>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            )

                        })
                    }
                    <Row style={{
                        position: 'fixed',
                        right: '1.5rem',
                        bottom: '75px',
                        backgroundColor: '#001C45',
                        color: 'white',
                        fontSize: '40px',
                        borderRadius: '30px',
                        padding: '0 10px',
                    }} onClick={() => listSelect.length > 0 ? setModalVisible(true) : ''}>
                        <div>
                            {
                                listSelect.length > 0 &&
                                <div style={{
                                    position: 'absolute',
                                    width: 30,
                                    height: 30,
                                    borderRadius: 15,
                                    fontSize: 24,
                                    right: -10,
                                    top: -12,
                                    backgroundColor: 'red',
                                    border: '3px solid'
                                }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        {listSelect.length}
                                    </div>
                                </div>
                            }

                            <ShoppingCartOutlined />
                        </div>
                    </Row>
                </div>
            </Skeleton >

            {/* Modal กติกา */}
            <Modal 
                title="กฏกติกามวยชุด" 
                visible={isOpenModalRules} 
                onCancel={()=>setIsOpenModalRules(false)}
                footer={[
                    <Button key="cancel" onClick={()=>setIsOpenModalRules(false)}>
                      ปิด
                    </Button>]}
                >
                    1. กรณีมีมวยที่ถูกไล่ลงถือเป็น โมฆะ ยกเลิกไม่ได้เสีย ไม่นำคู่นั้นมาคิดเงิน ส่วนคู่ที่เหลือให้นำมาคิดผลปรกติ<br/>
                    2. กรณีมวยสลับมุมถือว่าได้เสียตามปกติ แต่จะทำการยึดตามชื่อนักมวยแทน<br/>
                    3. กรณีขึ้นชื่อมวยผิดยกเลิกไม่ได้เสีย ส่วนคู่ที่เหลือให้นำมาคิดผลปรกติ<br/>
                    4. กรณีมวยยกเลิก จะยกเลิกเฉพาะคู่นั้น ส่วนคู่ที่เหลือจะนำมาคิดผลตามปรกติ<br/>
                    5. กรณีเปิดราคาผิดเกิน 30% ตามท้องตลาดขอสงวนสิทธ์ในการยกเลิกเฉพาะคู่นั้นไม่ได้เสีย ส่วนคู่ที่เหลือให้นำมาคิดผลปรกติ<br/>
                    6. เปิดราคาผิดมุม(เช่นแดงต่อแต่เปิดน้ำเงินต่อ) ขอสงวนสิทธ์ในการยกเลิกเฉพาะคู่นั้นไม่ได้เสีย ส่วนคู่ที่เหลือให้นำมาคิดผลปรกติ<br/>
                    7. ขอสงวนสิทธิ์ ยกเลิกทุกกรณีกับสมาชิกกับที่มีการเดิมพันผิดปกติหรือผิดธรรมชาติไม่ว่ากรณีใดๆ<br/>
                    8. กรณีมวยเสมอ คืนทุนไม่มีการได้เสีย ไม่นำคู่นั้นมาคิดเงิน ส่วนคู่ที่เหลือให้นำมาคิดผลปรกติ<br/>
                    9. หากการทายผลคู่ใดคู่หนึ่งแพ้(ผิด) จะถือว่าเสียทั้งทั้งบิล(บิลตาย)<br/>
                    10. ขอสงวนสิทธิ์ที่จะไม่ประกาศเป็นโมฆะหรือคืนเงินวางเดิมพัน เนื่องจากการแก้ไขหรือเปลี่ยนแปลงผล ชื่อทีม หรือรายละเอียดการแข่งขันอื่นใดที่เกิดขึ้น 72 ชั่วโมงหลังจากเริ่มเวลาการแข่งขัน หรือเดิมพันที่ตัดสินแล้ว<br/>
                    11. ในกรณีที่มีเปลี่ยนแปลงจำนวนยกที่จะทำการชกจาก 5 ยก เป็น 3 ยก หรือ จาก 3 ยกเป็น 5 ยก เป็นต้น ขอสงวนสิทธิ์ในการยกเลิกการเดิมพัน เฉพาะคู่นั้น ส่วนคู่อื่นๆ จะคิดเงินปรกติ โดยไม่ต้องแจ้งล่วงหน้า<br/>
                    12.การตัดสินให้ถือเอาผลการรวมคะแนนและชูมือของกรรมการเป็นหลัก หากมีการยกเลิกภายหลังที่กรรมการชูมือไปแล้ว จะไม่เป็นผลย้อนหลัง (กรรมการชูมือฝ่ายไหนก็ให้ถือเป็นผลแพ้ชนะ การพลิกคำตัดสินหลังจากนั้นถือว่าไม่มีผลย้อนหลัง) ยกเว้นกรณีกรรมการชูมือผิดข้าง แล้วกรรมการเรียกมาชูมือใหม่<br/>
            </Modal>
        </>
    )
}

export default MuayStepFightComponent