import Config from "../config/config";
import AuthenService from './AuthenService'
import axiosClient from './axios/axiosClient'

export default class ConfigValueService {
    constructor() {
    }

    static async MIN_PRICE_WITHDRAW() {
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL+'/config/MIN_PRICE_WITHDRAW',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Config.BACKEND_APP_SECRET,
            },
        };

        const returnData = await axiosClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    };

    static async ENABLE_WITHDRAW_CREDIT() {
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL+'/config/ENABLE_WITHDRAW_CREDIT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Config.BACKEND_APP_SECRET,
            },
        };

        const returnData = await axiosClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    };

    static async MIN_PRICE_PLAYMUAY() {
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL+'/config/MIN_PRICE_PLAYMUAY',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Config.BACKEND_APP_SECRET,
            },
        };

        const returnData = await axiosClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    };
    
    static async getBankConfig() {
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL + '/banksystem/getConfig',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthenService.getAcessToken(),
            },
        };

        const returnData = await axiosClient(config).then(function (response) {
            return response.data
        })
        return returnData
    }
}