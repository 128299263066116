import React from "react";
import { Row, Col, Card, Button, message, Image, Typography, Empty, Skeleton } from 'antd';
import { CommentOutlined, SettingOutlined } from "@ant-design/icons";
import copy from 'copy-to-clipboard';
import config from "../../config/config";
import WalletService from "../../service/WalletService";
import ConfigValueService from "../../service/ConfigValueService";

export default class DepositDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      depositAccount: {
        bankName: null,
        accountNumber: null,
        accountName: null
      },
      loading: false,
      ENABLE_DEPOSIT_CREDIT: false,
      BANK_SYSTEM_DEPOSIT_MSG: ''
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    ConfigValueService.getBankConfig().then((res) => {
      if (res.status === "0000" && res.data.BANK_SYSTEM_ENABLE_DEPOSIT_CREDIT) {
        this.setState({ ENABLE_DEPOSIT_CREDIT: res.data.BANK_SYSTEM_ENABLE_DEPOSIT_CREDIT, BANK_SYSTEM_DEPOSIT_MSG: res.data.BANK_SYSTEM_DEPOSIT_MSG })

        WalletService.getDepositBank().then(res => {
          if (res.data.status == "0000") {
            this.setState({
              depositAccount: {
                bankName: res.data.data.bankName,
                accountNumber: res.data.data.bankNumber,
                accountName: res.data.data.accountName
              },
              loading: false
            })
          } else {
            this.setState({ loading: false })
          }
        })

      } else {
        this.setState({ ENABLE_DEPOSIT_CREDIT: false, loading: false })
      }
    })

  }

  handelCopyAccountNumber() {
    copy(this.state.depositAccount.accountNumber)
    message.success("คักลอก")
  }

  render() {
    return (
      <div>
        <center>
          <div className='DepositDetailComponent'>
            <Skeleton active loading={this.state.loading}>
              {(this.state.depositAccount?.bankName && this.state.ENABLE_DEPOSIT_CREDIT) ?
                <Row className='card-deposit-detail content-center'>
                  <Col span={24}>
                    <center>
                      <img src={WalletService.getBankData(this.state.depositAccount.bankName).image.src} height='60px' width='auto' /><br />
                      <span>{WalletService.getBankData(this.state.depositAccount.bankName).text}</span>
                    </center>
                  </Col>
                  <Col span={24}>
                    <center><br />
                      <p>โอนเงินเพื่อฝากเข้าบัญชี ตามบัญชีธนาคารนี้</p>
                      <p>ชื่อบัญชี : {this.state.depositAccount.accountName}</p>
                      <p>เลขที่บัญชี : {this.state.depositAccount.accountNumber}</p>
                    </center>
                  </Col>
                  <Col span={24}>
                    <center><Button type='primary' onClick={() => this.handelCopyAccountNumber()}>คัดลอก</Button></center>
                  </Col>
                </Row>
                :
                <Row className='card-deposit-detail content-center'>
                  <Col span={24}>
                    <center>
                      <h3 style={{ color: '#FFF' }}>ปิดระบบฝากชั่วคราว</h3>
                    </center>
                  </Col>
                </Row>
              }
            </Skeleton>
          </div>
        </center>
        <div style={{ color: 'red', width: '100%', maxWidth: '600px', margin: 'auto', marginTop: 20 }}>
          อ่านรายละเอียดข้อบังคับก่อนโอนเงิน
          <ul>
            <li> สำหรับลูกค้าที่ลงทะเบียนด้วยบัญชีธนาคารไทยพาณิชย์ กรุณา ส่งหลักฐานการโอนเงินให้แอดมินด้วยค่ะ</li>
            <li> กรุณาใช้บัญชีที่ลงทะเบียนไว้ในการโอนเงินทำรายการเท่านั้น</li>
            <li> กรณีโอนเงินแล้วเงินไม่เข้าภายใน 5 นาที หรือมีปัญหาอื่นๆ กดติดต่อเรา เพื่อติดต่อเจ้าหน้าที่</li>
            {
              !!this.state.BANK_SYSTEM_DEPOSIT_MSG &&
              <li> {this.state.BANK_SYSTEM_DEPOSIT_MSG}</li>
            }
          </ul>
          <a href={config.CONTACT_US_LINK} target="_blank"><Button type='primary' bg-color='green' icon={<CommentOutlined />} block >ติอต่อเรา</Button></a>
        </div>
      </div>
    )
  };

};