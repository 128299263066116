import React from "react";
import liff from '@line/liff';
import config from "../../config/config";
import { Button,notification,message } from "antd";
import AuthenService from "../../service/AuthenService";
import { FacebookFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      userLineID: '',
      pictureUrl: ''
    };
  }

  componentDidMount = async() => {
    await liff.init({ liffId: config.LINE_LIFF_ID }).catch(err=>console.log(err));
    if (liff.isLoggedIn()) {
      message.loading({ content: 'Loading...', key:'login' })
      let getProfile = await liff.getProfile();
      this.setState({
        name: getProfile.displayName,
        userLineID: getProfile.userId,
        pictureUrl: getProfile.pictureUrl,
      });
      //สมัครสมาชิกในกรณีที่ไม่พบบัญชีในระบบ ล็อกอินถ้ามีบัญชีแล้ว เพื่อขอ access token//
      AuthenService.signin(liff.getIDToken(),new URLSearchParams(this.props.location.search).get("a")).then(res=>{
        if(!res.status){//if login fail
          liff.logout();
          notification['warning']({
            message: res.msg,
          })
          this.componentDidMount()
        }else{
          if(new URLSearchParams(this.props.location.search).get("a")=="" || new URLSearchParams(this.props.location.search).get("a")==null){
            this.props.history.push("/home");
          }else{
            window.location.replace(config.CONTACT_US_LINK);
          }
        }
        message.success({ content: 'finish', key:'login',duration:1 })
      })
    }else{
      let agentId=new URLSearchParams(this.props.location.search).get("a")
      liff.login({redirectUri:`${config.HOSTNAME}/login?a=${agentId || ''}`});
    }
  }
  render() {
    return (
        <>
          <div className="content-center container-fullscreen" style={{paddingTop:30}}>
            <p style={{marginTop:35,marginBottom:50,fontWeight:300, fontSize:"1.3rem", textAlign:'center'}}>
              แทงมวยออนไล์<br/>ฝากเงิน ถอนเงิน รวดเร็วอัตโนมัติ
            </p>
            {this.state.userLineID && (
              <div>
                <p>ชื่อ {this.state.name}</p>
                <p>Line ID {this.state.userLineID}</p>
                <img alt='pic' src={this.state.pictureUrl} width='100' /><br/>
                <Button type="primary" shape="round" size="large" onClick={()=>{liff.logout();this.componentDidMount()}}>ออกจากระบบ</Button>
              </div>
            )}
          </div>
        </>
    );
  }
}

export default Login;
