import React,{ useState } from "react";
import { Link } from "react-router-dom";

const GameMsgBoardComponent = (props) => {
    const msgList=props.msg ? props.msg : []
    return (
        <div className='GameMsgBoardComponent'>
            {props.children}
            {msgList.map((item,key)=><GameMsgItem key={key} text={item.text} isMe={item.userId}/>)}
        </div>
    )
}

const GameMsgItem = (props)=>{
    return (
        <div className={`GameMsgItem ${props.isMe ? 'right': 'left'}`} >
            <span>{props.text}</span>
        </div>
    )
}
export default GameMsgBoardComponent;
export {GameMsgItem}