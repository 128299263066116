import React, { useEffect, useState } from "react";
import { Skeleton, Row, Col, Button, Empty, Collapse } from 'antd';
import axiosClient from '../../service/axios/axiosClient';
import Config from '../../config/config';
import MuayPakYokService from '../../service/MuayPakYokService';

export default () => {
    const [loading, setLoading] = useState(true)
    const [myorderSummaryList, setMyorderSummaryList] = useState([])
    const [limit, setLimit] = useState(6)

    useEffect(() => {
        MuayPakYokService.getMyorderSummary(limit).then(res => {
            if (res.status) {
                setMyorderSummaryList(res.data)
                setLoading(false)
            }
        })
    }, [limit])

    function callback(key) {
        // console.log(key);
    }

    return (
        <div>
            <Skeleton active loading={loading}>
                {/* Content list muay history */}
                <Row className='header-muay-pakyok-title' align='middle'>
                    <Col span={16} style={{ justifyContent: 'center' }}>รายการเดิมพัน</Col>
                    <Col span={4}>ยอดแทง</Col>
                    <Col span={4}>ได้/เสีย</Col>
                </Row>
                {myorderSummaryList.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                {/* data muay history */}
                {myorderSummaryList?.map((item,key) => (
                    <Collapse className='custom-collapse' collapsible="header" key={key}>
                        <Collapse.Panel header={HeaderC(item)}>
                            {item.orderList?.map((orderItem,keyOrder) => (
                                <Row className='header-muay-pakyok' key={keyOrder}>
                                    {/* shoot muay name detail */}
                                    <Col span={16} style={{ justifyContent: 'left', paddingLeft: 10 }}>
                                        {
                                            orderItem.shoot === 'RED' ?
                                                <div className={'shot-name-detail'}>
                                                    <div style={{ color: '#DB0000' }}>@ แดง{getTextRate(orderItem.rate.redWeight,orderItem.rate.blueWeight).textRateFull}</div>
                                                    <div style={{ color: 'rgba(77, 77, 77, 1)' }}>{orderItem.playRatePrefix} ◾ {new Date(orderItem.createdAt).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}น.</div>
                                                </div>
                                                :
                                                <div className={'shot-name-detail'}>
                                                    <div style={{ color: '#0052CD' }}>@ น้ำเงิน{getTextRate(orderItem.rate.blueWeight,orderItem.rate.redWeight).textRateFull}</div>
                                                    <div style={{ color: 'rgba(77, 77, 77, 1)' }}>{orderItem.playRatePrefix} ◾ {new Date(orderItem.createdAt).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}น.</div>
                                                </div>
                                        } </Col>

                                    {/* shoot price */}
                                    <Col span={4}>
                                        {orderItem.shootPrice}
                                    </Col>
                                    {/* price win lose */}
                                    <Col span={4}>
                                        {
                                            orderItem.status === 0 &&
                                            <div style={{ color: '#ffc107' }}>รอออกผล</div>
                                        }
                                        {
                                            orderItem.status === 1 &&
                                            <div style={orderItem.playPrice.winPrice - orderItem.playPrice.lossPrice < 0 ? { color: '#DB0000' } : { color: '#00BF2A' }}>
                                                {orderItem.playPrice.winPrice - orderItem.playPrice.lossPrice > 0 && '+'}{orderItem.playPrice.winPrice - orderItem.playPrice.lossPrice}
                                            </div>
                                        }
                                        {
                                            orderItem.status === 2 &&
                                            <div style={{ color: '#AAA' }}>คืนโพย</div>
                                        }
                                    </Col>
                                </Row>
                            ))}
                        </Collapse.Panel>
                    </Collapse>
                ))}

                {myorderSummaryList.length >= limit &&
                    <center>
                        <Button type='primary' style={{ marginTop: 10 }} onClick={() => { setLimit(limit + 5) }}>เพิ่มเติม</Button>
                    </center>
                }
            </Skeleton>
        </div>
    )
}

const HeaderC=(item)=>(
    <Row className='header-muay-pakyok'>
        {/* shoot muay name detail */}
        <Col span={16} style={{ justifyContent: 'left', paddingLeft: 10 }}>
            <div className={'shot-name-detail'}>
                <div style={{color: '#DB0000'}}>🔴 {item.dataDetail.redMan.firstName} {item.dataDetail.redMan.lastName}</div>
                <div style={{ color: '#0052CD' }}>🔵 {`${item.dataDetail.blueMan.firstName} ${item.dataDetail.blueMan.lastName}`}</div>
                <div>{item.dataDetail.winner=="RED"? "🏆 แดงชนะ" : item.dataDetail.winner=="BLUE"? "🏆 น้ำเงินชนะ" : item.dataDetail.winner=="DRAW"? "🏆 เสมอ" : item.dataDetail.winner=="CANCEL"? "🏆 ยกเลิกชก" : item.dataDetail.winner=="FOUL"&& "🏆 ไล่ลง"} {(item.dataDetail.winner&&item.dataDetail.announceMsg)&& `(${item.dataDetail.announceMsg})`} </div>
                <div style={{ color: 'rgba(77, 77, 77, 1)' }}>🕒 {new Date(item.dataDetail.date).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</div>
            </div>
        </Col>

        {/* shoot price */}
        <Col span={4}>
            {item.sumShootPrice}
        </Col>

        {/* price win lose */}
        <Col span={4}>
            {
                item.dataDetail.winner == null &&
                <div style={{ color: '#ffc107' }}>รอออกผล</div>
            }
            {
                (item.dataDetail.winner == "BLUE" || item.dataDetail.winner == "RED") &&
                <div style={item.sumWinPrice - item.sumLossPrice < 0 ? { color: '#DB0000' } : { color: '#00BF2A' }}>
                    {item.sumWinPrice - item.sumLossPrice> 0 && '+'}{item.sumWinPrice - item.sumLossPrice}
                </div>
            }
            {
                item.dataDetail.winner == "CANCEL" &&
                <div style={{ color: '#AAA' }}>ยกเลิก</div>
            }
            {
                item.dataDetail.winner == "FOUL" &&
                <div style={{ color: '#AAA' }}>ไล่ลง</div>
            }
            {
                item.dataDetail.winner == "DRAW" &&
                <div style={{ color: '#AAA' }}>เสมอ</div>
            }
        </Col>
    </Row>
)

function getTextRate(myRate,vRate){
    let textRate,rateType,textRateFull;
    if( myRate > vRate){//ถ้าแดงต่อ
        rateType="ต่อ"
        textRate=`${myRate}/${vRate}`
    }else if(myRate <  vRate){//ถ้าแดงรอง
        rateType="รอง"
        textRate=`${vRate}/${myRate}`
    }else{
        rateType="เสมอ"
        textRate=`${myRate}/${vRate}`
    }
    return {textRate,rateType,textRateFull:`${rateType} ${textRate}`}
}