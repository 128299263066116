import Config from "../../config/config";
import AuthenService from "../AuthenService";
const axios = require('axios');

const axiosClient = axios.create();
axiosClient.interceptors.response.use((response)=>{
    return response;
  }, async function (error) {
    console.log(error.message)
    if(error.message==="Network Error"){
        window.location.replace(Config.maintenancePageURL) //Maintenance page
    }else{
        if(error.response.status==503){
            if(error.response.data.errors.message == 'server maintenance'){ window.location.replace(Config.maintenancePageURL);} //Maintenance page
        }else if(error.response.status==401){
            await AuthenService.logout()
            window.location.reload()
        }
    }
    return Promise.reject(error);
  });

  export default axiosClient