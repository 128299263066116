import React, { useEffect, useState } from "react";
import { Tabs, Skeleton, Row, Col } from 'antd';

import MuayStepFightComponent from '../../components/Contents/MuayStepFightComponent';
import ResultRoundFightComponent from '../../components/Contents/ResultRoundFightComponent';

const MuayOtherView = (props) => {
    const { TabPane } = Tabs;

    function callback(key) {
        // console.log(key);
    }
    
    return (
        <>
            <div className='muay-home'>
                <Tabs defaultActiveKey="1" onChange={callback} centered>
                    <TabPane tab="มวยชุด" key="1">
                        <MuayStepFightComponent />
                    </TabPane>
                    <TabPane tab="ผลการชก" key="2">
                        <ResultRoundFightComponent />
                    </TabPane>
                </Tabs>
            </div >
        </>

    )
}

export default MuayOtherView