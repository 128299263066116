import React from "react";
import { Row, Col, Card, Button, message, Image, Typography, Result, Skeleton, Input } from 'antd';
import { CheckCircleFilled, SettingOutlined } from "@ant-design/icons";
import WalletService from "../../service/WalletService";
import ConfigValueService from "../../service/ConfigValueService";

export default class DepositDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      widdrawLoading: false,
      withdrawMin: 1,
      withdrawMax: 50000,
      withdrawPrice: 0,
      enableWithdraw: false,
      iswithdrawSuccess: false,
      config_note: ''
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    ConfigValueService.getBankConfig().then(res => {
      if (res.status === "0000") {
        this.setState({ enableWithdraw: res.data.BANK_SYSTEM_ENABLE_WITHDRAW_CREDIT, withdrawMin: Number(res.data.BANK_SYSTEM_MINIMUN_WITHDRAW), config_note: res.data.BANK_SYSTEM_WITHDRAW_MSG, loading: false })
      }
      this.setState({ loading: false })
    })
  }

  onWithdraw() {
    if (this.state.withdrawPrice < this.state.withdrawMin) return message.warning(`ถอนอย่างน้อย ${this.state.withdrawMin}`);
    if (this.state.withdrawPrice > this.state.withdrawMax) return message.warning(`ถอนสูงสุด ${this.state.withdrawMax}`);
    WalletService.withdraw(this.state.withdrawPrice).then(res => {
      if (res.status) {
        this.setState({ iswithdrawSuccess: true, withdrawPrice: 0 })
      } else {
        message.warning(res.msg)
      }
    })
  }

  hendelInputWidthdrawPrice = (price) => {
    if (price != '') {
      price = parseInt(price)
      if (!isNaN(price)) {
        this.setState({ withdrawPrice: price })
      }
    } else {
      this.setState({ withdrawPrice: 0 })
    }
  }

  render() {
    return (
      <div className='WithdrawDetailComponent'>
        <Skeleton active loading={this.state.loading}>
          {this.state.enableWithdraw ?
            this.state.iswithdrawSuccess ?
              <Result status="success" title="ถอนเงินสำเร็จ รอการอนุมัติ" extra={<Button type='primary' onClick={() => this.setState({ iswithdrawSuccess: false })}>ทำรายการอีก</Button>} />
              :
              <Row gutter={[0, 20]}>
                <Col span={24} style={{ padding: 10 }}>
                  <center>
                    <Input disabled={this.state.widdrawLoading} value={this.state.withdrawPrice || ''} placeholder='จำนวนเงินที่ต้องการถอน' onChange={(event) => this.hendelInputWidthdrawPrice(event.target.value)} suffix='฿' />
                  </center>
                  <span style={{ color: 'red' }}>*ถอนขั่นต่ำ {this.state.withdrawMin} บาท สูงสุด {this.state.withdrawMax} บาท, ถอนได้ 5 ครั้งต่อวัน</span>
                  {
                    !!this.state.config_note &&
                    <span style={{ color: 'red' }}>* {this.state.config_note}</span>
                  }
                </Col>
                <Col span={24}>
                  <center><Button type='primary' loading={this.state.widdrawLoading} onClick={() => this.onWithdraw()} icon={<CheckCircleFilled />}>ถอน</Button></center>
                </Col>
              </Row>
            :
            <Row className='content-center' bg-color='primary'>
              <Col span={24}>
                <center>
                  <h3 style={{ color: '#FFF' }}>ปิดระบบถอนชั่วคราว</h3>
                </center>
              </Col>
            </Row>
          }
        </Skeleton>
      </div>
    )
  };

};