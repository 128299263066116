import Config from "../config/config";
import axiosClient from './axios/axiosClient'
import AuthenService from './AuthenService'

export default class MuayStepService {
    constructor() { }

    static async getMuayStepList() {
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL + '/muaystep/playlist',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthenService.getAcessToken()
            },
        };
        const returnData = await axiosClient(config).then(async (response) => {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    }

    static async playMuayStep(data) {
        var config = {
            method: 'post',
            url: Config.API_CONFIG.API_URL + '/muaystep/play',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthenService.getAcessToken()
            },
            data: data
        };
        const returnData = await axiosClient(config).then(async (response) => {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    }

    static async getMuayStepOrder(limit) {
        var config = {
            method: 'get',
            url: Config.API_CONFIG.API_URL + '/muaystep/myorder/'+limit,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthenService.getAcessToken()
            },
        };
        const returnData = await axiosClient(config).then(async (response) => {
            return { status: true, data: response.data }
        }).catch(function (error) {
            return { status: false, msg: error.response.data.errors }
        });
        return await returnData
    }

}